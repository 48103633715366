import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as moment from "moment";
import {ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(public router: Router, 
    private http: HttpClient ) { }

  login(hash_login:string, password:string ) {
    return this.http.post<any>(`${environment.apiURL}/employee/login`,
    {
      hash_login: hash_login,
        password: password
    });
}
  public setSession(authResult:any) {
    // const expiresAt = moment().add(authResult.expiresIn,'second');
    console.log('Logged User', authResult);
    if (authResult.success) {
        localStorage.setItem('token', authResult.success.token);
        localStorage.setItem('isLoggedin', 'true');
    } else if (authResult.error) {
        console.error('Server Response:', authResult.error)
        this.logout();
    }
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('isLoggedin');
    this.router.navigate(['/authentication/login']);
  }

  isLoggedIn() {
      if (localStorage.getItem('token')) {
          return true;
      }
      return false;
  }

  getUserData() {
      return this.http.get<any>(`${environment.apiURL}/api/auth/me`);
  }

  getUser() {
      return JSON.parse(localStorage.getItem('user'));
  }

  public setUserData(data) {
      localStorage.setItem('user', JSON.stringify(data));
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

}